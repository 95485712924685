$(document).ready(function(){
    $(document).on('click', '.method', methodChanged);
    $(document).on('click', '.currency', currencyChanged);
    $(document).on('click', '.checkout', checkout);
    $(document).on('load', '#calculator', panelUpdated);

    $(function () {
        if ($('#calculator').length > 0) {
            panelUpdated();
        }
    });

    function methodChanged(evt) {
        evt.preventDefault();

        var paymentMethod = $(evt.target).closest('.method');
        var method = paymentMethod.data('id');

        $('input[name=method]').val(method);

        // send data
        sendDataToController();
    }

    function currencyChanged(evt) {
        evt.preventDefault();

        var paymentCurrency = $(evt.target).closest('.currency');
        var currency = paymentCurrency.data('id');

        $('input[name=currency]').val(currency);

        // send data
        sendDataToController();
    }

    function sendDataToController() {
        var method = $('input[name=method]').val();
        var currency = $('input[name=currency]').val();
        var iframe = $('input[name=iframe]').val();
        var mobile_app = $('input[name=mobile_app]').val();
        var demo = $('input[name=demo]').val();

        $.ajax({
            url: baseUrl + 'calculator/panel', type: 'POST', dataType: 'json',
            data: {
                method: method,
                currency: currency,
                iframe: iframe,
                mobile_app: mobile_app,
                demo: demo
            },
            beforeSend: function() {
                $("#calculator").hide();
                $(".container-loader").show();
            },
            success: function (rdata) {
                $('.next-btn').show();
                panelUpdated(rdata);
            },
            error: function () {
            }
        });
    }

    function checkout() {
        $.ajax({
            url: baseUrl + 'calculator/set-checkout', type: 'POST', dataType: 'json',
            success: function (rdata) {
                if (rdata.success){
                    $('.next-btn').show();
                    if(iFrame) {
                        window.open(rdata.redirect, '_parent');
                    } else {
                        window.location.href = rdata.redirect;
                    }
                    panelUpdated(rdata);
                }
            },
            error: function () {

            }
        });
    }

    //********************************************************************************* //

    function panelUpdated(data){
        $('#calculator').load(baseUrl + "calculator/panel", function() {
            $(".container-loader").hide();
            $("#calculator").show();
        });
    }
});